.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    width: 100%;
    color: var(--text-6, #263238);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      width: 100%;
      color: var(--text-6, #263238);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;

      &.left {
        text-align: start;
      }
    }
  }
}
