.pdf {
  width: 98%;
  height: 250px;
  flex: 1;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  flex: 1;

  width: 100%;
  max-width: 920px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    max-width: 500px;
    text-align: center;

    h4 {
      color: var(--theme-sulmed-main, #22096f);
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
    }

    p {
      color: var(--text-4, #455a64);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    width: 100%;

    .text {
      margin: 0;
      font-size: 14px;
      color: var(--text-color-6, #263238);
    }

    .bold {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
