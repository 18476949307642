.container {
  padding: 0px;
  overflow-x: hidden;
  position: relative;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .topBar {
    width: 100%;
    height: 40px;
    background-color: var(--primary-color-main);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    ul {
      display: flex;
      flex-direction: row;

      li {
        margin: 0 24px;
        list-style: none;
        color: var(--basic-white);
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }

  .navbar {
    height: 80px;

    background-color: var(--basic-white);
    display: flex;
    flex-direction: row;

    align-items: center;

    img {
      width: 100px;
      cursor: pointer;

      &:hover {
        transform: scale(1.03);
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        margin: 0 24px;
        list-style: none;
        color: var(--primary-color-main);
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }

  .searchField {
    width: 100vw;
    height: 90px;
    background-color: var(--basic-background);
    display: flex;
    flex-direction: row;
    align-items: center;

    .cart {
      cursor: pointer;
      position: relative;

      .notification {
        position: absolute;
        bottom: -1px;
        right: 15px;
        width: 12px;
        height: 12px;
        border-radius: 20px;
        background-color: var(--error-color-main);
        color: var(--basic-white);
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .location {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .icons {
      width: 100%;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        margin: 0px 18px;
        cursor: pointer;
      }
    }
  }

  .banner {
    height: 550px;
    width: 100vw;
    overflow: hidden;
    position: relative;

    .bannerBackground {
      width: 100vw;
      height: 550px;
      object-fit: cover;
    }

    .bannerContent {
      width: 440px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      h1 {
        color: var(--basic-white);
        font-size: 64px;
      }
    }
  }

  .packages {
    padding: 80px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 650px) {
      padding: 80px 10px;
    }
  }

  .services {
    height: 100vh;
    width: 100vw;
    padding: 80px 0px;
    background-color: var(--basic-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .servicesRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .service {
        width: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 40px;

        .serviceTitle {
          font-weight: bolder;
          font-size: 14px;
          margin: 0px;
          color: var(--primary-color-main);
        }
      }
    }
  }

  .partners {
    height: 300px;
    width: 100vw;
    background-color: var(--primary-color-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .partnersRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 30px;
    }
  }

  .faq {
    height: 80vh;
    width: 100vw;
    background-color: var(--basic-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .simulator {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 64px 32px;

    .title {
      max-width: 430px;
      width: 100%;
      color: var(--theme-sulmed-main, #22096f);
      font-family: Helvetica;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: start;
      margin: 8px 0px;
    }

    .text {
      max-width: 430px;
      width: 100%;
      color: var(--text-5, #37474f);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start;
      margin: 8px 0px;
    }

    @media screen and (max-width: 1050px) {
      flex-direction: column;

      .title, .text {
        max-width: 90%;
        width: 90%;
        text-align: center;
      }
    }

    .column {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 30px;
    }
  }

  .footer {
    height: 250px;
    background-color: var(--primary-color-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footerContent {
      width: 100%;
      max-width: 1041px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .footerColumn {
        width: 100%;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.centered {
          align-items: center;
        }

        &.end {
          align-items: flex-end;
        }
      }

      .logo {
        width: 100px;
      }

      p {
        color: var(--basic-white);
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        margin: 0px;
        text-align: start;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    .navbar {
      justify-content: center;

      ul {
        display: none;
      }
    }

    .banner {
      .bannerContent {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .searchField {
      width: 90%;
      height: auto;
      padding: 20px 0px;
      margin: 0px auto;
      flex-direction: column-reverse;

      .location {
        margin-top: 20px;
      }

      .icons {
        display: none;
      }
    }

    .packages .packagesRow {
    }

    .services {
      height: auto;
    }

    .services .servicesRow {
      flex-wrap: wrap;
    }

    .partners .partnersRow {
      height: auto;
      flex-direction: column;
    }

    .services p {
      text-align: center !important;
    }

    .faq {
      height: auto;
      padding: 30px 0px;
    }

    .footer {
      height: auto;
      padding: 20px 0px 40px 0px;

      .footerContent {
        flex-direction: column !important;

        .footerColumn {
          width: 80%;
          margin: 15px 0px;

          &.end {
            align-items: center;
          }
        }

        p {
          text-align: center;
        }
      }
    }
  }
}

.plansAtributes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0px;
  align-items: flex-start;
}

.atributes {
  display: flex;
  justify-content: left;
  p {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    text-align: left;
    color: #455a64;

    img {
      margin-top: 5px;
    }
  }
}

.whatsapp {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;

  img {
    width: 50px;
    height: 50px;
    margin-right: 60px;
    margin-bottom: 50px;
  }
}
