.options {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 900px) {
  .options {
    width: 88%;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;

    .buttons {
      width: 100%;
      margin: 20px 0px;
    }
  }
}

@media screen and (max-width: 310px) {
  .options .buttons {
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
  }
}
