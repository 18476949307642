.container {
  display: flex;
  align-items: start;
  justify-content: center;

  width: 100%;
  height: 70px;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    width: 100%;
    max-width: 120px;

    text-align: center;

    .wrapper {
      display: flex;
      align-items: center;

      width: 100%;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 16px;
        height: 16px;
        border-radius: 50%;
        padding: 2px;
        box-sizing: border-box;

        font-size: 10px;
        color: var(--basic-white);

        &.pending {
          background-color: var(--text-color-1);
        }

        &.inProgress {
          background-color: var(--primary-color-light);
        }

        &.concluded {
          background-color: var(--primary-color-dark);
        }
      }

      .line {
        flex: 1;
        border: 0;
        margin: 0;

        &.pending,
        &.inProgress {
          border-bottom: 2px dashed var(--text-color-1);
          margin: 0 2px;
        }

        &.concluded {
          border-bottom: 2px solid var(--primary-color-light);
        }
      }
    }

    .title {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 400;
      margin: 0 2px;

      &.pending {
        color: var(--text-color-3);
      }

      &.inProgress {
        color: var(--text-color-5);
      }

      &.concluded {
        color: var(--text-color-3);
      }
    }

    &:last-child .line:last-child {
      border: 0;
    }
  }
}
