.container {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 100%;
  z-index: 1;

  * {
    color: var(--text-color-3);
  }
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  background-color: transparent;
  border: 0;
  padding: 10px;

  width: 100%;

  border-radius: 6px;
  border: 1px solid var(--text-color-1);
  background: var(--basic-white);
}

.options {
  display: flex;
  flex-direction: column;
  gap: 8px;

  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  max-height: 140px;
  overflow-y: auto;
  background: var(--basic-background);
  padding: 0;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  text-align: start;
  margin-top: 4px;
}

.option {
  padding: 10px;

  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: var(--text-color-1);
  }
}
