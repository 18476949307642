.container {
    height: auto;
    border-radius: 8px;
    padding: 24px;
    background-color: var(--basic-white);
    border: 1px solid var(--text-color-1);

    .flags {
      width: 140px;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .infoButton {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                margin-left: 10px;
            }

            .modalFlags {
                width: 307px;
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: white;
                padding: 18px 16px;
                border-radius: 8px;
                box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
                z-index: 999;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                cursor: default;

                .creditCardFlag {
                    width: 62px;
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .creditCardField {
            width: 100%;
            position: relative;

            .creditCardFlag {
                width: 50px;
                position: absolute;
                top: 30px;
                right: 8%;
            }
        }

        @media screen and (max-width: 650px) {
          flex-direction: column;
          justify-content: center;
        }
    }
}

@media screen and (max-width: 800px) {
    .container {

        .flags {
            display: none;
        }

        .title {
          width: 100%;
        }

        .row {
            input {
                width: 100% !important;
            }
        }
    }
}