.field {
  width: 100%;
  text-align: start;
  padding-bottom: 10px;
  position: relative;

  @media screen and (max-width: 900px) {
    padding-bottom: 0px;
  }

  input {
    width: 100%;
    padding: 9.5px 10px;
    border-radius: 6px;
    border: 1px solid var(--text-color-1);
    font-size: 14px;
    color: var(--text-color-3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    background-color: var(--basic-white);

    &:hover {
      border: 1px solid var(--text-color-2);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      &:hover {
        border: 1px solid var(--text-color-1);
      }

      background-color: var(--basic-grey);
      color: var(--text-color-5);
      padding-right: 24px;
    }

    &.start {
      text-align: start;
    }

    &.center {
      text-align: center;
    }

    &.end {
      text-align: end;
    }
  }

  label {
    font-size: 14px;
    color: var(--text-color-3);
    background-color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: all ease-in-out 400ms;
    vertical-align: middle;
    transform-origin: 0%;
    transition: all ease-in-out 400ms;
    pointer-events: none;

    &.labelValueOn {
      transform: scale(0.75) translateY(-1.5rem);
    }
  }

  .container {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      color: var(--text-color-3);
    }
  }

  i {
    font-size: 10px;
    color: red;
    margin: 0px;
    margin-top: 5px;
    margin-left: 5px;
  }
}

.field {
  input:focus + label {
    transform: scale(0.75) translateY(-1.5rem);
  }
}
