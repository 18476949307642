.container {
  width: 98%;
  height: 100%;
  
  a {
    text-decoration: none;
  }

  .viewer {
    max-width: 800px;
    width: 100%;
    height: 480px;
    border: 10px solid #e8ecee;
    border-radius: 8px;

    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .modalImage {
    max-width: 800px;
    width: 100%;
    max-height: 800px;
    height: 100%;

    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

.button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100px;
  padding: 0px 8px;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  p {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    left: 20px;
    margin: 0px;
    font-size: 14px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    z-index: 1;
  }
}

.teste::-webkit-file-upload-button {
  visibility: hidden;
}

.teste::before {
  display: none;
  width: 100px;
  position: absolute;
}

.teste {
  color: transparent;
  top: 10px;
  left: 20px;
  z-index: 99;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .container {
    .viewer {
      width: 90%;
      height: 300px;
    }
  }
}
