.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;

  padding: 16px 140px;

  border: 2px solid var(--primary-color-light);
  border-radius: 7px;
  background-color: #ffff;

  width: 100%;
  min-height: 174px;

  box-sizing: border-box;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;

    padding: 10px;
    text-align: center;

    .placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--text-color-4);
      margin: 0;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: var(--text-color-5);
      margin: 0px;
    }

    .info {
      font-weight: 400;
      font-size: 12px;
      color: var(--text-color-3);
      margin: 0px;
    }

    .inputs {
      display: flex;
      gap: 24px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 8px;
    width: 100%;
  }

  .confirmText {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color-5);
  }

  .confirmButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;

    button {
      min-width: min(200px, 100%);
    }
  }

  .buttonWrapper {
    position: absolute;
    right: 16px;
  }

  .subquestion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .status {
    color: var(--theme-sulmed-main, #22096f);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  .warning {
    color: var(--text-color-4, #455a64);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    text-align: center;

    button {
      color: var(--theme-sulmed-light, #00aff8);
      background-color: transparent;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 4px;
    padding: 16px;

    .wrapper {
      .inputs {
        flex-direction: column;
        gap: 8px;

        > div {
          padding-bottom: 0;
        }
      }

      .confirmButtons {
        justify-content: space-around;
        width: 100%;
      }
    }

    .buttonWrapper {
      position: static;
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
  width: 100%;
  flex: 1;

  .pdf {
    width: 100%;
    flex: 1;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
