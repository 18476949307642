.container {
    max-width: 736px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;

    .ticket {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--primary-color-main);
        cursor: pointer;

        img {
            margin-right: 5px;
        }
    }

    .value {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-size: 24px;
            font-weight: bolder;
            color: var(--primary-color-main);
            margin: 0px;
        }
    }
}
