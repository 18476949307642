.container {
    max-width: 736px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: start;

    img {
        margin-top: 15px;
    }

    .column {
        width: 100%;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;

        .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                width: 11px;
                margin-right: 10px;
            }
        }
    }

    .value {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;
    }
}
