.datepicker {
  width: 100%;
  height: 40px;
  padding: none;
  padding-bottom: 10px;

  > div {
    width: 100% !important;
    background-color: #fff;
    border: none;
    overflow: hidden;

    > div {
      width: 100% !important;
    }

    label {
      width: fit-content;
      font-size: 14px;
      color: var(--text-color-3);
      line-height: 10px;
      background-color: transparent;
    }

    input {
     height: 19px;
     color: #000000;
     border: none;
     padding: 9.5px 16px;
     font-size: 13px;
     font-weight: 400;
    }

    fieldset {
      // border: none;
      border-color: var(--text-color-1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }
  }
}
