.container {
  display: flex;
  align-items: start;
  gap: 10px;
  width: 100%;
  text-align: start;

  * {
    box-sizing: border-box;
  }

  .root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    background-color: white;
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;

    border-radius: 4px;
    border: 2px solid var(--text-color-2);
    outline: none;

    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &:focus {
      border: 2px solid var(--primary-color-light);
    }
  }

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid var(--primary-color-main);
    outline: none;

    background-color: var(--primary-color-main);
  }

  .label {
    color: var(--text-color-4, #455a64);
    font-size: 14px;
    margin: 0;
    cursor: pointer;
  }
}
