.appEnvironment {
    display: flex;
    width: 100%;
    height: 25px;
    color: var(--basic-white);
    justify-content: center;
    align-items: center;

    span {
        text-transform: uppercase;
    }
}