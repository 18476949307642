i {
  margin-top: 10px;
  font-size: 14px;
}

.fileBox {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #78909c;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  label {
    cursor: pointer;
  }

  i {
    font-size: 12px;
    margin-top: 10px;
    color: var(--text-color-6);
  }

  .trash {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #78909c;
  }

  .text {
    font-size: 12px;
    margin: 0px;
    margin-top: 15px;
    color: #37474f;
    text-align: center;

    span {
      color: #00aff8;
    }

    &.grey {
      font-size: 12px;
      color: "#78909C";
      margin: 0px;
      margin-top: 5px;
      font-weight: 600;
    }
  }
}

.fileInput {
  width: 100%;
  height: 100%;
  display: none;
  background-color: #333;
  cursor: pointer;
}

.displayImg {
  width: 280px;
  object-fit: cover;
}
