.fileBox {
  margin-right: 30px;
}

.pdf {
  width: 98%;
  height: 250px;
  flex: 1;
}

.fileInput {
  width: 100%;
  height: 100%;

  display: none;
  background-color: #333;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--Theme-Sulmed-Main, #22096f);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  margin-right: 10px;
}

.tableHeader {
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  background-color: var(--primary-color-background);
  color: var(--primary-color-dark);
}

.tableContent {
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  background-color: var(--basic-white);

  p {
    font-size: 14px;

    &.email {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.bolder {
      font-weight: bolder;
    }
  }

  svg {
    color: var(--primary-color-main);
    cursor: pointer;
  }

  @media screen and (max-width: 650px) {
    p {
      font-size: 12px;

      &.email {
        max-width: 94px;
      }

      &.bolder {
        font-weight: normal;
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.start {
    align-items: start;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;
  }
}

.fileUpload {
  background: var(--basic-white);
  border: 1px dashed var(--text-color-3);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    margin: 2px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--text-color-3);

    span {
      font-weight: 400;
      line-height: 14px;
      color: var(--primary-color-light);
    }
  }

  &.uploaded {
    cursor: default;
    border: 1px dashed var(--success-color-light);
    flex-direction: row;
    justify-content: space-between;

    p {
      color: var(--success-color-light);
    }

    svg {
      color: var(--text-color-4);
      cursor: pointer;
    }
  }
}
