.field {
    width: 90%;
    position: relative;

    textarea {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid var(--text-color-1);
        font-size: 14px;
        color: var(--text-color-3);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        background-color: var(--basic-white);

        &:hover {
            border: 1px solid var(--text-color-2);
        }

        &:focus {
            outline: none;
        }
    }

    label {
        font-size: 14px;
        color: var(--text-color-3);
        position: absolute;
        top: 13px;
        left: 10px;
        transition: all ease-in-out 400ms;
        vertical-align: middle;
        transform-origin: 0%;
        transition: all ease-in-out 400ms;
        pointer-events: none;
        background-color: var(--basic-background);

        &.labelValueOn {
            transform: scale(0.75) translateY(-1.7rem);
        }
    }
}

.field {
    textarea:focus + label {
        transform: scale(0.75) translateY(-1.7rem);
    }
}
