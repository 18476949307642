
.cptItem {
  color: var(--text-6, #263238);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  span {
    color: var(--text-3, #78909C);
  }
}
