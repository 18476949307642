.modal {
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.plan {
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background: var(--basic-white, #fff);
  padding-bottom: 30px;

  p,
  h3 {
    margin: 0px;
  }

  &.global {
    border: 0.5px solid var(--theme-sulmed-main, #22096f);

    .header {
      background: var(--theme-sulmed-main, #22096f);
    }
  }

  &.ambulatorial {
    border: 0.5px solid #00aff8;

    .header {
      background: #00aff8;
    }
  }

  &.odontologico {
    border: 0.5px solid #b41e8e;

    .header {
      background: #b41e8e;
    }
  }

  .header {
    width: 230px;
    height: 112px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    text-align: center;
    border-radius: 0px 0px 24px 0px;
    color: var(--basic-white, #fff);

    .type {
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .title {
      font-family: Helvetica;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      margin: 6px 0px;
    }

    .subtitle {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    @media screen and (max-width: 600px) {
      text-align: start;
    }
  }

  .body {
    gap: 16px;
    padding: 16px 12px 16px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;

    .atributes {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      img {
        margin: 4px 6px 0px 0px !important;
      }
    }
  }
}
