.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  background-color: var(--primary-color-background);
}

.body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 24px;
  background-color: var(--basic-white);

  .documentation {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 3px;
    }
  }

  .beneficiarieEmail {
    position: relative;
    text-align: start !important;
    max-width: 195px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 950px) {
  .body {
    overflow-x: scroll;
  }
}
