.content {
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0px;
    margin: 0 auto;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .content {
        flex-direction: column-reverse;
        align-items: center;
        padding: 0px 0px 40px 0px;
    }
}
