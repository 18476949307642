.container {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 450px;
  position: relative;

  @media screen and (max-width: 650px) {
    height: 100%;
    min-width: 100%;
  }

  img {
    width: 260px;
    height: 260px;
    object-fit: cover;

    @media screen and (max-width: 650px) {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
    }
  }
}

.document {
  height: 100%;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
