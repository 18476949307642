.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.plansAtributes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0px;
  padding: 0px 10px;
  align-items: flex-start;
}

.informativeDownloadButton {
  color: var(--primary-color-main);
  text-decoration: none;
}

.atributes {
  font-size: 16px;
  margin: 0px 0;

  p {
    font-size: 12px;
    text-align: left;
  }
}
