.container {
    padding: 0px;
    overflow-x: hidden;

    .navbar {
        height: 80px;
        padding: 0 120px;
        background-color: var(--primary-color-main);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img {
            width: 100px;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }

        ul {
            display: flex;
            flex-direction: row;

            li {
                margin: 0 24px;
                list-style: none;
                color: var(--basic-white);
                font-size: 12px;
                cursor: pointer;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }
    }

    .searchField {
        width: 100vw;
        height: 90px;
        background-color: var(--basic-background);
        display: flex;
        flex-direction: row;
        align-items: center;

        .cart {
            cursor: pointer;
            position: relative;

            .notification {
                position: absolute;
                bottom: -1px;
                right: 15px;
                width: 12px;
                height: 12px;
                border-radius: 20px;
                background-color: var(--error-color-main);
                color: var(--basic-white);
                font-size: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .location {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .icons {
            width: 100%;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                margin: 0px 18px;
                cursor: pointer;
            }
        }
    }

    .content {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 40px 0px;
        margin: 0 auto;

        .contentColumn {
            max-width: 509px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .text,
            li {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: start;
                color: var(--text-color-5);
            }

            h1 {
                width: 100%;
                font-weight: 700;
                font-size: 42px;
                line-height: 48px;
                display: flex;
                align-items: center;
                text-align: start;
                margin: 0px;
                color: var(--primary-color-main);
            }

            .title {
                font-weight: bolder;
                font-size: 22px;
                line-height: 26px;
                text-align: start;
                color: var(--text-color-6);
                margin: 0px;
            }

            ul,
            ol {
                font-weight: 400;
                font-size: 14px;
                text-align: start;
                color: var(--text-color-5);
            }

            &.resume {
                max-width: 420px;
                width: 90%;

                .banner {
                    width: 100%;
                }
            }

            .partners {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding: 20px 0px;
                margin: 0 auto;
            }

            .productDetails {
                list-style-type: none;
                padding: 0px;
                color: var(--text-color-3);

                > li {
                    text-indent: -5px;
                }

                > li:before {
                    content: "-";
                    text-indent: -5px;
                }
            }

            .location {
                font-size: 12px;
                color: var(--text-color-5);
                display: flex;
                flex-direction: row;
                align-items: center;

                span {
                    color: var(--primary-color-main);
                    margin-right: 5px;
                }
            }
        }
    }

    .footer {
        height: 250px;
        background-color: var(--primary-color-main);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .footerContent {
            width: 100%;
            max-width: 1041px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .footerColumn {
                width: 100%;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &.centered {
                    align-items: center;
                }

                &.end {
                    align-items: flex-end;
                }
            }

            .logo {
                width: 100px;
            }

            p {
                color: var(--basic-white);
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                cursor: pointer;
                margin: 0px;
                text-align: start;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .container {
        .navbar {
            justify-content: center;

            ul {
                display: none;
            }
        }

        .searchField {
            width: 90%;
            height: auto;
            padding: 20px 0px;
            margin: 0px auto;
            flex-direction: column-reverse;

            .location {
                margin-top: 20px;
            }

            .icons {
                display: none;
            }
        }

        .content {
            flex-direction: column;

            .contentColumn {
                .resume {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }

                .partners {
                    flex-direction: column;
                }
            }
        }

        .footer {
            height: auto;
            padding: 20px 0px 40px 0px;

            .footerContent {
                flex-direction: column !important;

                .footerColumn {
                    width: 80%;
                    margin: 15px 0px;

                    &.end {
                        align-items: center;
                    }
                }

                p {
                    text-align: center;
                }
            }
        }
    }
}
