.footer {
    padding: 50px 0px;
    background-color: var(--primary-color-main);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .footerContent {
        width: 100%;
        max-width: 1041px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .footerColumn {
            width: 100%;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.start {
                align-items: flex-start;
            }

            &.centered {
                align-items: center;
            }

            &.end {
                align-items: flex-end;
            }

            p {
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.28px;
            }

            .row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                img {
                    margin: 0px 12px;
                    cursor: pointer;
                }
            }


            .footerContacts {
                padding-right: 12px;

                p {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.28px;
                    display: flex;
                    cursor: default;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        margin-right: 8px;
                    }
                }
            }
        }

        .logo {
            width: 100px;
        }

        p {
            color: var(--basic-white);
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            cursor: pointer;
            margin: 0px;
            text-align: start;
        }
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        height: auto;
        padding: 20px 0px 40px 0px;

        .footerContent {
            flex-direction: column !important;

            .footerColumn {
                width: 80%;
                margin: 15px 0px;

                &.end, &.start {
                    align-items: center;
                }
            }

            p {
                text-align: center;
            }
        }
    }
}
