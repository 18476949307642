.container {
    padding: 0px;

    .contact {
        height: 40px;
        max-width: 100vw;
        background-color: var(--primary-color-dark);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
    }

    .navbar {
        height: 80px;
        max-width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            li {
                margin: 0px 20px;
                list-style: none;
            }
        }

        .logo {
            width: 130px;
            height: 30px;
        }

        .button {
            padding: 10px 20px;
            border-radius: 40px;
            background-color: var(--primary-color-light);
        }

        .consultantID {
            padding: 5px 10px;
            border-radius: 40px;
            border: 3px solid var(--primary-color-light);

            .consultantImage {
                width: 50px;
                background-color: var(--primary-color-light);
                height: 35px;
                border-radius: 100%;
                margin-right: 10px;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .consultantName {
                width: 140px;
                font-size: 14px;
                color: var(--primary-color-main);
                font-weight: bolder;
                margin: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            
            .consultantRole {
                font-size: 12px;
                color: var(--primary-color-light);
                font-weight: bolder;
                margin: 0px;
            }
        }
    }

    .banner {
        width: 100vw;
        height: 75vh;
        background-color: var(--primary-color-light);
        overflow: hidden;
        position: relative;

        .bannerTitle {
            width: 350px;
            font-size: 46px;
            color: var(--basic-white);
            position: absolute;
            top: 30%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .bannerIcon {
            position: absolute;
            bottom: 70px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .bannerBackground {
            width: 100vw;
            height: 75vh;
            object-fit: cover;
        }
    }

    .content {
        width: 850px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .plan {
        background: linear-gradient(var(--basic-white) 0 0) padding-box,
        linear-gradient(to right, var(--primary-color-light), 
        var(--primary-color-dark)) border-box;
        color: #313149;
        padding: 0px;
        border: 3px solid transparent;
        border-radius: 10px;
        overflow: hidden;

        .planHeader {
            width: 250px;
            height: 194px;
            padding: 0px;
            position: relative;

            .planHeaderTitle {
                width: 150px;
                position: absolute;
                left: 20px;
                bottom: 70px;
                margin-left: auto;
                margin-right: auto;
                text-align: start;

                .description {
                    font-size: 10px;
                    color: var(--basic-white);
                }
            }

            .planHeaderBackground {
                width: 100%;
            }
        }

        .planBody {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px;

            .planIconBody {
                margin-right: 10px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .navbar {
        ul, li, .consultantID {
            display: none;
        }
    }
}
