.card {
  width: 98%;
  padding: 15px 30px;
  border-radius: 8px;
  background-color: var(--basic-white);
  border: 1px solid var(--text-2, #cfd8dc);

  @media screen and (max-width: 700px) {
    padding: 40px 20px;
  }
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.end {
    justify-content: flex-end;
  }
}

.field {
  width: 100%;
  background-color: #fff;
  position: relative;

  .select {
    width: 100%;
    background-color: white !important;
    padding: 9.5px 10px;
    border-radius: 6px;
    border: 1px solid var(--text-color-1);
    font-size: 14px;
    color: var(--text-color-3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    background-color: var(--basic-white);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    .options {
      max-height: 150px;
      overflow-y: scroll;
      width: 100%;
      position: absolute;
      top: 40px;
      left: 0px;
      border-radius: 6px;
      border: 1px solid var(--text-color-1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background-color: var(--basic-background);
      z-index: 9999;

      .option {
        font-size: 14px;
        padding: 10px;
        text-align: start;
        cursor: pointer;

        &:hover {
          background-color: var(--text-color-1);
        }
      }
    }
  }

  img {
    background-color: #fff;
    width: 25px;
    padding: 3px 5px;
    position: absolute;
    top: 8px;
    right: 5px;
  }

  img {
    background-color: #fff;
    width: 25px;
    padding: 3px 5px;
    position: absolute;
    top: 8px;
    right: 5px;
  }
}

.plans {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;

  .plan {
    display: flex;
    width: 48%;
    padding: 16px 0px 16px 12px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--theme-sulmed-dark, #0a027e);
    background: var(--basic-white, #fff);

    &.global {
      border: 1px solid #0a027e;

      .header {
        color: #22096f;
      }
    }

    &.ambulatorial {
      border: 1px solid #00aff8;

      .header {
        color: #00aff8;
      }
    }

    &.odontologico {
      border: 1px solid #b41e8e;

      .header {
        color: #b41e8e;
      }
    }

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    .header {
      width: 98%;
      padding-bottom: 10px;
      font-family: Roboto;
      font-style: normal;
      text-align: start;
      gap: 5px;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      .toggle {
        cursor: pointer;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        margin: 0px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        margin: 4px 0px;
      }
    }

    .body {
      height: 340px;
      margin-top: 10px;
      overflow-y: auto;
      width: 100%;
      gap: 8px;
      display: flex;
      padding: 0px 12px 18px 0px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: start;

      .atributes {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        img {
          margin: 2px 6px 0px 0px !important;
        }

        p {
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0px;
        }
      }
    }

    .footer {
      width: 100%;
      padding: 16px 12px;
      text-align: end;

      .ticket {
        color: var(--text-5, #37474f);
        text-align: right;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
      }

      .total {
        color: var(--text-5, #37474f);
        text-align: right;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0px;
        letter-spacing: 1px;
      }
    }
  }
}
