.row {
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.file {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 16px;
    text-align: center;
    color: var(--text-color-4);
    margin: 7px 0px;
  }

  i {
      font-size: 14px;
      text-align: center;
      color: var(--text-color-3);
      margin: 0px;
  }

  img {
    width: 90px;
    margin-bottom: 10px;
  }
}