.icon {
  margin-right: 8px;
}

b {
  margin-right: 5px;
}

.documents {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
}

.row {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .column {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;
    padding: 0px 20px;
  }
}

@media screen and (max-width: 650px) {
  .row {
    flex-direction: column;
    justify-content: center;

    .column {
      width: 100%;
    }
  }
}
