.container {
    padding: 0px;
    overflow-x: hidden;

    .navbar {
        height: 80px;
        padding: 0 120px;
        background-color: var(--primary-color-main);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img {
            width: 100px;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }

        ul {
            display: flex;
            flex-direction: row;

            li {
                margin: 0 24px;
                list-style: none;
                color: var(--basic-white);
                font-size: 12px;
                cursor: pointer;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }
    }

    .searchField {
        width: 100vw;
        height: 90px;
        background-color: var(--basic-background);
        display: flex;
        flex-direction: row;
        align-items: center;

        .cart {
            cursor: pointer;
            position: relative;

            .notification {
                position: absolute;
                bottom: -1px;
                right: 15px;
                width: 12px;
                height: 12px;
                border-radius: 20px;
                background-color: var(--error-color-main);
                color: var(--basic-white);
                font-size: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .location {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .icons {
            width: 100%;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                margin: 0px 18px;
                cursor: pointer;
            }
        }
    }

    .banner {
        height: 550px;
        width: 100vw;
        overflow: hidden;
        position: relative;

        .bannerFilter {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(3, 37, 71, 0.37);
        }

        .bannerBackground {
            width: 100vw;
            height: 550px;
            object-fit: cover;
        }

        .bannerContent {
            position: absolute;
            bottom: 20%;
            left: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;

            h2 {
                max-width: 900px;
                text-align: start;
                font-weight: 400;
                display: flex;
                align-items: center;
                color: var(--basic-white);
                margin: 0px;
                font-size: 46px;
                font-weight: bolder;
            }

            .icon {
                width: 36px;
                margin-right: 10px;
            }
        }
    }

    .packages {
        width: 100vw;
        padding: 80px 0px;
        background-color: var(--primary-color-background);
        display: flex;
        justify-content: center;
        align-items: center;

        .packagesRow {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .package {
                width: 250px;
                height: 520px;
                background: var(--basic-white);
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                margin: 0px 12px;

                .packageBanner {
                    width: 250px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    overflow: hidden;
                    position: relative;

                    .packageBannerImage {
                        width: 160%;
                        height: 195px;
                        object-fit: cover;
                        object-position: left;
                        margin-top: -10px;
                    }

                    .layer {
                        width: 102%;
                        position: absolute;
                        bottom: 0px;
                        left: -2px;
                    }
                }

                .packageContent {
                    height: 190px;
                    padding: 0px 25px;

                    .productDetails {
                        list-style-type: none;
                        padding: 0px;
                        padding-left: 5px;
                        font-weight: 400;
                        text-align: start;
                        color: var(--basic-black);

                        > li {
                            text-indent: -5px;
                            font-size: 12px;
                        }

                        > li:before {
                            content: "-";
                            text-indent: -5px;
                        }
                    }
                }
            }
        }
    }

    .about {
        width: 100vw;
        height: 20vw;
        background-color: var(--basic-white);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .aboutContent {
            text-align: center;
        }
    }

    .footer {
        height: 250px;
        background-color: var(--primary-color-main);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .footerContent {
            width: 100%;
            max-width: 1041px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .footerColumn {
                width: 100%;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &.centered {
                    align-items: center;
                }

                &.end {
                    align-items: flex-end;
                }
            }

            .logo {
                width: 100px;
            }

            p {
                color: var(--basic-white);
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                cursor: pointer;
                margin: 0px;
                text-align: start;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .container {
        .navbar {
            justify-content: center;

            ul {
                display: none;
            }
        }

        .banner {
            height: 500px;

            .bannerContent {
                width: 85%;
                left: 0;
                right: 0;
                top: 55%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .searchField {
            width: 90%;
            height: auto;
            padding: 20px 0px;
            margin: 0px auto;
            flex-direction: column-reverse;

            .location {
                margin-top: 20px;
            }

            .icons {
                display: none;
            }
        }

        .packages .packagesRow {
            justify-content: flex-start;
            padding: 5px 20px;
            overflow-x: scroll;
            width: 90vw;
        }

        .packages {
            height: auto;
        }

        .about {
            height: auto;
            padding: 40px 0px;

            .aboutContent {
                width: 80%;
            }
        }

        .footer {
            height: auto;
            padding: 20px 0px 40px 0px;

            .footerContent {
                flex-direction: column !important;

                .footerColumn {
                    width: 80%;
                    margin: 15px 0px;

                    &.end {
                        align-items: center;
                    }
                }

                p {
                    text-align: center;
                }
            }
        }
    }
}
