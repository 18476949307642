.searchField {
    width: 100vw;
    height: 90px;
    background-color: var(--basic-background);
    display: flex;
    flex-direction: row;
    align-items: center;

    .cart {
        cursor: pointer;
        position: relative;

        .notification {
            position: absolute;
            bottom: -1px;
            right: 15px;
            width: 12px;
            height: 12px;
            border-radius: 20px;
            background-color: var(--error-color-main);
            color: var(--basic-white);
            font-size: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .location {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .icons {
        width: 100%;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
            margin: 0px 18px;
            cursor: pointer;
        }
    }
}

.modalContent {
    height: 450px;
}

@media screen and (max-width: 1200px) {
    .searchField {
        width: 90%;
        height: auto;
        padding: 20px 0px;
        margin: 0px auto;
        flex-direction: column-reverse;

        .location {
            margin-top: 20px;
        }

        .icons {
            display: none;
        }
    }
}
