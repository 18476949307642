.datepicker {
  width: 100%;
  height: 40px;
  padding: none;

  > div {
    width: 100% !important;
    background-color: #fff;
    border: none;
    overflow: hidden;
    padding-top: 0px;

    > div {
      width: 100% !important;
    }

    input {
     height: 1px;
     color: #78909c;
     border: none;
     padding: 19px;
     font-size: 14px;
    }

    fieldset {
      // border: none;
      border-color: var(--text-color-1);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }
  }
}

.container {
  width: 90%;
}

.content {
  width: 950px;
}

.informative {
  font-size: 14px;
  color: var(--primary-color-main);
}

.row {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  padding: 5px 0px;

  &.start {
    justify-content: flex-start;
    align-items: center;
  }

  &.centered {
    justify-content: center;
    align-items: center;
  }


  &.end {
    justify-content: flex-end;
    align-items: center;
  }
}

.error {
  font-size: 10px;
  color: red;
  margin: 0px;
  margin-top: 10px;
  margin-left: 5px;
}


@media screen and (max-width: 1000px) {
  .container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    float: left;
  }
}

.deleteButton {
  // margin-left: 47px;
  margin-top: 8px;
  color: var(--text-color-3);
  z-index: 1;
  transition: filter 0.2s;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
}

.select {
  width: 100%;
  background-color: white;
  padding: 9.5px 10px;
  border-radius: 6px;
  border: 1px solid var(--text-color-1);
  font-size: 14px;
  color: var(--text-color-3);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--basic-white);

  .options {
    max-height: 150px;
    overflow-y: scroll;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0px;
    border-radius: 6px;
    border: 1px solid var(--text-color-1);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    background-color: var(--basic-background);
    z-index: 9999;

    .option {
      font-size: 14px;
      padding: 10px;
      text-align: start;
      cursor: pointer;

      &:hover {
        background-color: var(--text-color-1);
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: none;
  }
}
