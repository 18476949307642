.container {
    width: 100%;
    padding: 30px 0px;

    .leftButton {
        position: relative;
        float: left;
    }

    .rightButton {
        position: relative;
        float: right;
    }
}

@media screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

