.chip {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  margin-left: 10px;

  &.green {
    border: 0.5px solid var(--success-main, #006f38);

    p {
      color: var(--success-main, #006f38);
    }
  }

  &.grey {
    border: 0.5px solid var(--text-3, #78909c);

    p {
      color: var(--success-main, #78909c);
    }
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.5px;

    img {
      margin-right: 5px;
    }
  }
}

.container {
  width: 100%;
  background-color: var(--basic-white);
  border: 1px solid var(--text-color-1);

  svg {
    cursor: pointer;
    color: var(--text-color-3);
  }

  .row {
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .trashIcon {
    display: block;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      width: 75%;
      display: flex;
      flex-direction: start;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    .row {
      max-width: 50px;
    }

    .header h1 {
      font-size: 16px !important;
    }

    .trashIcon {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .container .header .title {
    flex-direction: column;

    h1 {
      margin-bottom: 13px;
    }
  }
}
