.container {
  p {
    background: #e8e8fc;
    padding: 3px 12px;
    border-radius: 12px;
    margin-left: 10px;
    color: var(--sulmed-color-dark);
    font-size: 12px;

    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
}
