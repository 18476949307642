.container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
}

@media screen and (max-width: 900px) {
  .container {
    align-items: center;
  }
}