.container {
  height: 70px;
  display: flex;
  padding: 0px 120px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color-main);
  color: var(--basic-white);

  p,
  img,
  svg {
    cursor: pointer;
  }

  img:hover,
  svg:hover {
    transform: scale(1.1);
  }

  .logo {
    width: 130px;
  }
}

.separator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brokerContainer {
  display: flex;
  width: 175px;
  height: 42px;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  border: 2px solid #30a8e0;
  border-radius: 24px;
  margin-right: 30px;
}

.brokerImg {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid #30a8e0;
}

.brokerText {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
}

.brokerName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.brokerDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .container {
    width: 100%;
    padding: 0px;
    justify-content: space-around;

    img {
      width: 100px;
    }
  }
}
