.brokerContainer {
  display: flex;
  height: 42px;
  flex-direction: row;
  align-items: center;
  padding: 5px 18px;
  border: 2px solid #30a8e0;
  border-radius: 24px;
}

.brokerImg {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 5px;

  img {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    min-width: 100%;
    min-height: 100%;
    border-radius: 30px;
  }
}

.brokerText {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
}

.brokerName {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.brokerDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .brokerContainer {
    margin-right: 0px;
  }

  .brokerImg img {
    width: 30px !important;
  }
}
