.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 98;
}

.button {
  all: unset;
  margin: 0px 5px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px;
  width: calc(100% - 64px);
  height: calc(100% - 64px);
  max-width: 700px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 98;

  border-radius: 8px;
  background: var(--basic-white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(97, 182, 85, 0.08);

  .title {
    color: var(--theme-sulmed-main, #22096f);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 6px;
  }

  .description {
    color: var(--text-color-3);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 24px;
  }

  .close {
    all: unset;

    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
  }
}
