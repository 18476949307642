.container {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--basic-white);
    border: 1px solid var(--text-color-1);
    border-radius: 8px;
    
    p {
        font-size: 18px;
        font-weight: thin !important;
        cursor: pointer;
        margin: 0px;
        color: var(--primary-color-main);

        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
