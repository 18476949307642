.pdf {
  width: 98%;
  height: 250px;
  flex: 1;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}