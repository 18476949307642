.container {
  width: 99%;
  // margin-top: 50px;

  .age {
    width: 100%;
    margin: 0px;
    // margin-bottom: 29px;
    padding: 9.5px 10px;
    border-radius: 6px;
    border: 1px solid var(--text-color-1);
    font-size: 14px;
    color: var(--text-color-3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    background-color: var(--basic-white);
  }

  .rowPlans {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    height: 44px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.large {
      width: 80%;
    }

    &.medium {
      width: 50%;

      &:first-child {
        margin-right: 20px;
      }
    }

    &.small {
      width: 20%;
    }

    @media screen and (max-width: 700px) {
      &.medium {
        width: 100%;

        &:first-child {
          margin: 0px;
        }
      }
    }
  }
}
