.header {
    padding: 16px 24px;
    background-color: var(--primary-color-background);
    color: var(--primary-color-dark);
}

.body {
    padding: 8px 24px;
    background-color: var(--basic-white);
}
