.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 8px;

    .close {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .contentRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        .contentColumn {
            width: 46%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
            padding-right: 10px;
        }
    }
}

.cardFooter {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .content .contentRow {
        flex-direction: column;

        .contentColumn {
            width: 100%;
            margin-top: 25px;
            align-items: flex-start;
        }
    }

    .cardFooter {
        flex-direction: column;
    }
}
