.container {
  width: 100%;
  display: none;
  flex-direction: column;
  padding: 8px;
  background-color: var(--basic-white);

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px 0px;

    input {
      max-height: 50px;
    }

    .totalValueField {
      width: 100%;
      padding: 10px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #f6f6f6;
      background: #f6f6f6;
    }
  }

  .trash {
    color: var(--text-color-3);
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: flex;
    padding: 0px;
  }
}
