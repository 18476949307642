.container {
  width: 90%;
  
  .documents {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .disapprovalJustification {
      font-size: 14px;
      text-align: left;
      margin: 0px;
    }
  
    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
  }
}
