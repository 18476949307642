.fileBox {
  display: flex;
  width: 100%;
  height: 48px;
  border-radius: 6px ;
  cursor: pointer;
  background-color: #fff;
  position: relative;
  text-align: start;
  
  label {
    cursor: pointer;
  }

  i {
    font-size: 12px;
    margin-top: 10px;
    color: var(--text-color-6);
  }

  p{
    margin-left: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #455A64;
    align-self: flex-start;
  }

  .placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50px;
    width: 100%;
    padding: 10px;
    margin: 14px 0px;
    background-color: #F6F6F6;
  }

  .trash {
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--basic-grey);
  }

  .text {
    font-size: 12px;
    margin: 0px;
    margin-top: 15px;
    color: #37474F;
  
    span {
      color: #00AFF8;
    }
  
    &.grey {
      font-size: 12px;
      color: "#78909C";
      margin: 0px;
      margin-top: 5px;
      font-weight: 600;
    }
  }
}

.fileInput {
  width: 100%;
  height: 100%;
  display: none;
  background-color: #333;
  cursor: pointer;
}

.displayImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  .fileBox {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
    padding: 5px 0px 15px 0px;
  }

  .placeholder {
    width: 90%;
    min-height: 60px !important;
  }
}

