.container {
  min-height: 100vh;
  max-width: 960px;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--basic-background);

  .content {
    min-height: 80vh;
    max-width: 950px;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .container {
    width: 90%;
  }
}
