.container {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
  
    &.start {
        align-items: start;
    }

    &.centered {
        align-items: center;
    }

    &.end {
        align-items: end;
    }
}

@media screen and (max-width: 425px) {
  .container {
    align-items:  center !important;
  }
}