.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff8E8;
  border-radius: 6px;
  padding: 10px 10px 10px 14px;
  color: #37474F;
  font-size: 12px;
  font-weight: 400;
  text-align: left;

  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  h5, p {
    margin: 0px;
  }

}