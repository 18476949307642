.container {
  width: 100%;
  padding: 50px 120px;
  background-color: var(--primary-color-main);

  p {
    color: var(--basic-white);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1050px) {
      flex-direction: column;
    }

    &.about {
      justify-content: flex-start;

      img {
        width: 128px;
        margin-bottom: 20px;
      }

      p {
        width: 207px;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1050px) {
  .container {
    padding: 50px 20px;

    p {
      width: 80% !important;
      text-align: center;
    }

    .row .column {
      align-items: center;
    }
  }
}
