.container {
  width: 100%;

  .header {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .button {
      width: 405px;
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      p {
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        margin: 0px;
        color: var(--text-3, #78909c);
        cursor: pointer;
      }

      .line {
        width: 405px;
        height: 3px;
        opacity: 0.1;
        background-color: #828a82;
      }

      &.global {
        &.active {
          p {
            font-size: 22px;
            opacity: 1;
            color: var(--theme-sulmed-main, #22096f);
          }

          .line {
            opacity: 1;
            background-color: var(--theme-sulmed-main, #22096f);
          }
        }
      }

      &.ambulatorial {
        &.active {
          p {
            font-size: 22px;
            opacity: 1;
            color: #00aff8;
          }

          .line {
            opacity: 1;
            background-color: var(--theme-sulmed-main, #00aff8);
          }
        }
      }

      &.odontologico {
        &.active {
          p {
            font-size: 22px;
            opacity: 1;
            color: #b41e8e;
          }

          .line {
            opacity: 1;
            background-color: var(--theme-sulmed-main, #b41e8e);
          }
        }
      }
    }
  }

  .plans {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding: 20px 20px 30px 0px;

    @media screen and (max-width: 1400px) {
      overflow-x: auto;
      justify-content: flex-start;
      width: 90vw;
    }
  }

  .footer {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .item {
      width: 16px;
      height: 10px;
      border-radius: 8px;
      background: var(--theme-sulmed-main, #22096f);

      &.active {
        width: 64px;
      }

      &.global {
        background: var(--theme-sulmed-main, #22096f);
      }

      &.ambulatorial {
        background: var(--theme-sulmed-main, #00aff8);
      }

      &.odontologico {
        background: var(--theme-sulmed-main, #b41e8e);
      }
    }
  }

  @media screen and (max-width: 1300px) {
    width: 100%;

    .header .button {
      width: 30%;

      p {
        font-size: 14px;
        font-weight: 400;
      }

      &.global {
        &.active {
          p {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      &.ambulatorial {
        &.active {
          p {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      &.odontologico {
        &.active {
          p {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      .line {
        width: 100%;
      }
    }
  }
}
